import axios from "axios";
import CryptoJS from 'crypto-js';


let Currenttoken = "";
const baseURL = process.env.React_App_baseURL;

const Logout = () => {
    // window.location.reload();
    // localStorage.clear();
    // window.location.href = '/';
}

const decryptData = (name) => {
    const ciphertext = localStorage.getItem(name);
    if (ciphertext) {
        return ciphertext;
    }
    return "";
};

const PostServiceCallToken = (apiName, postData) => {
    Currenttoken = decryptData('JWT');
    return axios
        .post(baseURL + apiName, postData, { headers: { "Authorization": `Bearer ${Currenttoken}` } })
        .then((response) => {
            if (response && response !== undefined && response !== null && response.data) {
                return response.data;
            } else {
                Logout();
            }
        }).catch(err => {
            Logout();
            return err;
        });
};

const GetServiceCall = (apiName) => {
    Currenttoken = decryptData('JWT');
    return axios
        .get(baseURL + apiName, { headers: { "Authorization": `Bearer ${Currenttoken}` } })
        .then((response) => {
            if (response && response !== undefined && response !== null && response.data) {
                return response.data;
            } else {
                Logout();
            }
        }).catch(err => {
            Logout();
            return err;
        });
};

const PostServiceCall = (apiName, postData) => {
    return axios
        .post(baseURL + apiName, postData)
        .then((response) => {
            if (response && response !== undefined && response !== null && response.data) {
                return response.data;
            } else {
                // Handle the case where no data is returned
            }
        }).catch(err => {
            let apierror = err.message;
            if (err.response && err.response.data.errors) {
                apierror = err.response.data.errors[0].msg;
            } else if (err.response && err.response.data.msg) {
                apierror = err.response.data.msg;
            }
            return apierror;
        });
};

const AuthService = {
    PostServiceCallToken,
    PostServiceCall,
    GetServiceCall,
    decryptData
};

export default AuthService;
